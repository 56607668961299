import React, { useContext, useEffect, Fragment, useState } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import * as AiIcons from 'react-icons/ai';
import * as HiIcons from 'react-icons/hi';
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isEqual,
  isSameDay,
  isSameMonth,
  isToday,
  parse,
  parseISO,
  startOfToday,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { toast } from 'react-toastify';

import { IReserva } from '../../types/Reserva';
import api from '../../services/api';
import Context, { IContext } from '../../context/Context';
import CustomAlert from '../CustomAlert';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

export default function Agenda() {
  const { setIsLoadingOverlay }: IContext = useContext(Context);

  const hoje = startOfToday();

  const [diaSelecionado, setDiaSelecionado] = useState(hoje);
  const [mesAtual, setMesAtual] = useState(format(hoje, 'MMM-yyyy'));
  const [reservas, setReservas] = useState<IReserva[]>([]);
  const [reservaEditando, setReservaEditando] = useState<IReserva | undefined>();

  const primeiroDiaDoMesAtual = parse(mesAtual, 'MMM-yyyy', new Date());

  const dias = eachDayOfInterval({
    start: primeiroDiaDoMesAtual,
    end: endOfMonth(primeiroDiaDoMesAtual),
  })

  function mesAnterior() {
    const primeiroDiaDoMesAnterior = add(primeiroDiaDoMesAtual, { months: -1 });
    setMesAtual(format(primeiroDiaDoMesAnterior, 'MMM-yyyy'));
  }

  function proximoMes() {
    const primeiroDiaDoProximoMes = add(primeiroDiaDoMesAtual, { months: 1 })
    setMesAtual(format(primeiroDiaDoProximoMes, 'MMM-yyyy'))
  }

  const reservasDoDiaSelecionado = reservas.filter((reserva) =>
    isSameDay(parseISO(formataData(reserva.datres)), diaSelecionado)
  )

  function formataData(data: string) {
    const parsedDate = parse(data, 'dd/MM/yyyy', new Date());
    const dataFormatada = format(parsedDate, "yyyy-MM-dd'T'HH:mm");

    return dataFormatada;
  }

  async function getReservas() {
    try {
      setIsLoadingOverlay(true);
      const response = await api.get('/reservas', {
        params: {
          sta: 'PAGO'
        }
      });

      if (response.status === 200) {
        setReservas(response.data);
      }
    } catch (error: any) {
      if (error.response.data) return toast.error(error.response.data.erro);
      toast.error('Erro ao buscar reservas');
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 500);
    }
  }

  async function putReserva(reserva: IReserva, novaData: Date) {
    try {
      setIsLoadingOverlay(true);
      const payload = {
        codesp: reserva.espaco.cod,
        datres: novaData.toISOString().substring(0, 10),
        sta: 'PAGO',
        codusu: reserva.usuario.cod
      }

      const response = await api.put(`/reservas/${reserva.cod}`, payload);

      if (response.status === 200) {
        toast.success('Reserva editada com sucesso.');
        getReservas();
      }
    } catch (error: any) {
      if (error.response.data.erro.includes('já reservado para data')) return toast.error(`${reserva.espaco.esp} já foi reservado para essa data.`);
      if (error.response.data) return toast.error(error.response.data.erro);
      toast.error('Erro ao editar reserva.');
    } finally {
      setReservaEditando(undefined);
      setIsLoadingOverlay(false);
    }
  }

  async function deletarReserva(id: number) {
    try {
      setIsLoadingOverlay(true);
      const response = await api.delete(`/reservas/${id}`);
      if (response.status === 204) {
        toast.success('Reserva cancelada com sucesso.');
        getReservas();
      }
    } catch (error: any) {
      if (error.response.data) return toast.error(error.response.data.erro);
      toast.error('Erro ao excluir reserva.');
    } finally {
      setIsLoadingOverlay(false);
    }
  }

  async function getPlanilha() {
    try {
      setIsLoadingOverlay(true);
      const response = await api.get('/reservas/exportar', {
        params: {
          sta:'PAGO',
          ordem:'desc'
        },
        responseType: 'blob'
      });
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Reservas.xlsx');
        document.body.appendChild(link);
        link.click();
      }
    } catch (error: any) {
      if (error.response.data) return toast.error(error.response.data.erro);
      toast.error('Erro ao gerar planilha.');
    } finally {
      setIsLoadingOverlay(false);
    }
  }

  async function handleDiaButtonClick(dia: Date) {
    if (reservaEditando) {
      if (isSameDay(parseISO(formataData(reservaEditando.datres)), dia)) return setReservaEditando(undefined);

      CustomAlert({
        title: `Alterando Data de Reserva ${reservaEditando.espaco.esp}`,
        message: `Deseja alterar a data de reserva para ${format(dia, 'dd/MM/yyyy', { locale: ptBR })}?`,
        onConfirm: async () => {
          await putReserva(reservaEditando, dia)
          setDiaSelecionado(dia);
        },
        onCancel: () => setReservaEditando(undefined)
      })

      return;
    }

    setDiaSelecionado(dia);
  }

  useEffect(() => {
    getReservas();
  }, []);

  return (
    <div className='w-full'>
      <h1 className='text-center text-3xl font-semibold sm:text-4xl mx-0 my-4'>Agenda</h1>
      <div className='max-w-md px-4 mx-auto sm:px-7 md:max-w-4xl md:px-6'>
        <div className='md:grid md:grid-cols-2 md:divide-x md:divide-gray-200'>
          <div className='md:pr-14'>
            <div className='flex items-center b'>
              <h2 className='flex-auto font-semibold text-gray-900'>
                {format(primeiroDiaDoMesAtual, 'MMMM yyyy', { locale: ptBR })}
              </h2>
              <button
                type='button'
                onClick={getPlanilha}
                className='-my-1.5 flex-auto items-center justify-center p-1.5 text-gray-400 hover:text-gray-500'
              >
                <span className='sr-only'>Baixar Planilha</span>
                <AiIcons.AiOutlineFileExcel className='w-5 h-5' aria-hidden='true' />
              </button>
              <button
                type='button'
                onClick={mesAnterior}
                className='-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500'
              >
                <span className='sr-only'>Mês Anterior</span>
                <AiIcons.AiOutlineLeft className='w-5 h-5' aria-hidden='true' />
              </button>
              <button
                onClick={proximoMes}
                type='button'
                className='-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500'
              >
                <span className='sr-only'>Próximo Mês</span>
                <AiIcons.AiOutlineRight className='w-5 h-5' aria-hidden='true' />
              </button>
            </div>
            <div className='grid grid-cols-7 mt-10 text-xs leading-6 text-center text-gray-500'>
              <div>D</div>
              <div>S</div>
              <div>T</div>
              <div>Q</div>
              <div>Q</div>
              <div>S</div>
              <div>S</div>
            </div>
            <div className='grid grid-cols-7 mt-2 text-sm'>
              {dias.map((dia, index) => (
                <div
                  key={dia.toString()}
                  className={classNames(
                    index === 0 && colStartClasses[getDay(dia)],
                    'py-1.5'
                  )}
                >
                  <button
                    type='button'
                    onClick={() => handleDiaButtonClick(dia)}
                    disabled={(reservaEditando &&
                      reservas.filter(reserva => reserva.espaco.cod === (reservaEditando?.espaco?.cod ?? 0))
                        .some(reserva => isSameDay(parseISO(formataData(reserva.datres)), dia) && !isSameDay(parseISO(formataData(reservaEditando?.datres)), dia)))}
                    className={classNames(
                      isEqual(dia, diaSelecionado) && 'text-white',
                      !isEqual(dia, diaSelecionado) &&
                      isToday(dia) &&
                      'text-red-500',
                      !isEqual(dia, diaSelecionado) &&
                      !isToday(dia) &&
                      isSameMonth(dia, primeiroDiaDoMesAtual) &&
                      'text-gray-900',
                      !isEqual(dia, diaSelecionado) &&
                      !isToday(dia) &&
                      !isSameMonth(dia, primeiroDiaDoMesAtual) &&
                      'text-gray-400',
                      isEqual(dia, diaSelecionado) && isToday(dia) && 'bg-red-500',
                      isEqual(dia, diaSelecionado) &&
                      !isToday(dia) &&
                      'bg-gray-900',
                      !isEqual(dia, diaSelecionado) && 'hover:bg-gray-200',
                      (isEqual(dia, diaSelecionado) || isToday(dia)) &&
                      'font-semibold',
                      `mx-auto flex h-8 w-8 items-center justify-center rounded-full
                      ${reservaEditando &&
                        !reservas.filter(reserva => reserva.espaco.cod === (reservaEditando?.espaco?.cod ?? 0))
                          .some(reserva => isSameDay(parseISO(formataData(reserva.datres)), dia)) ?
                        'animate-shake' : reservaEditando && !isSameDay(parseISO(formataData(reservaEditando.datres)), dia) ?
                          'cursor-not-allowed' : 'cursor-pointer'}`
                    )}
                  >
                    <time dateTime={format(dia, 'yyyy-MM-dd')}>
                      {format(dia, 'dd')}
                    </time>
                  </button>

                  <div className='w-1 h-1 mx-auto mt-1'>
                    {reservas.some((reserva) =>
                      isSameDay(parseISO(formataData(reserva.datres)), dia)
                    ) && (
                        <div className='w-1 h-1 rounded-full bg-sky-500' />
                      )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <section className='mt-12 md:mt-0 md:pl-14'>
            <h2 className='font-semibold text-gray-900'>
              Reservas do Dia{' '}
              <time dateTime={format(diaSelecionado, 'yyyy-MM-dd')}>
                {format(diaSelecionado, 'dd MMMM yyy', { locale: ptBR })}
              </time>
            </h2>
            <ol className='mt-4 space-y-1 text-sm leading-6 text-gray-500'>
              {reservasDoDiaSelecionado.length > 0 ? (
                reservasDoDiaSelecionado.map((reserva) => (
                  <Reserva
                    reserva={reserva}
                    key={reserva.cod}
                    setReservaEditando={setReservaEditando}
                    deletarReserva={deletarReserva}
                  />
                ))
              ) : (
                <p>Sem Reservas Para o Dia Selecionado.</p>
              )}
            </ol>
          </section>
        </div>
      </div>
    </div>
  )
}

function Reserva(
  {
    reserva,
    setReservaEditando,
    deletarReserva
  }:
    {
      reserva: IReserva,
      setReservaEditando: React.Dispatch<React.SetStateAction<IReserva | undefined>>,
      deletarReserva: (id: number) => void
    }) {
  return (
    <li className='flex items-center px-4 py-2 space-x-4 group rounded-xl focus-within:bg-gray-100 hover:bg-gray-100'>
      <img
        src={
          reserva.usuario.usu?.includes('Tiburcio') ?
            'https://media.licdn.com/dms/image/D4D03AQFAp04XL06M6g/profile-displayphoto-shrink_800_800/0/1688586805712?e=1727913600&v=beta&t=utlIDoOe2MOMNCZLEvLIM9xY_GdRXDUzaS4c0zyI_LM' :
            'https://png.pngtree.com/png-vector/20220608/ourlarge/pngtree-unknown-user-profile-person-faceless-png-image_4816462.png'
        }
        alt=''
        className='flex-none w-10 h-10 rounded-full'
      />
      <div className='flex-auto'>
        <p className='text-gray-900 font-semibold'>{reserva.espaco.esp}</p>
        <p className='mt-0.5'>
          {reserva.usuario?.usu} {reserva.obs ?  `- Obs: ${reserva.obs}` : ''}
        </p>
      </div>
      <Menu
        as='div'
        className='relative opacity-0 focus-within:opacity-100 group-hover:opacity-100'
      >
        <div>
          <MenuButton className='-m-2 flex items-center rounded-full p-1.5 text-gray-500 hover:text-gray-600'>
            <span className='sr-only'>Open options</span>
            <HiIcons.HiDotsVertical className='w-6 h-6' aria-hidden='true' />
          </MenuButton>
        </div>

        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <MenuItems className='absolute right-0 z-10 mt-2 origin-top-right bg-white rounded-md shadow-lg w-36 ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className='py-1'>
              <MenuItems>
                {({ active }: any) => (
                  <MenuItem v-slot="{ close }">
                    <div
                      onClick={() => setReservaEditando(reserva)}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700 cursor-pointer',
                        'block px-4 py-2 text-sm hover:bg-gray-100'
                      )}
                    >
                      Alterar Data
                    </div>
                  </MenuItem>
                )}
              </MenuItems>
              <MenuItems>
                {({ active }: any) => (
                  <MenuItem>
                    <div
                      onClick={() => CustomAlert({
                        title: 'Cancelamento de Reserva',
                        message: 'Deseja prosseguir com o cancelamento da reserva?',
                        onConfirm: () => deletarReserva(reserva.cod),
                        onCancel: () => { },
                      })}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700 cursor-pointer',
                        'block px-4 py-2 text-sm hover:bg-gray-100'
                      )}
                    >
                      Cancelar Reserva
                    </div>
                  </MenuItem>
                )}
              </MenuItems>
            </div>
          </MenuItems>
        </Transition>
      </Menu>
    </li>
  )
}

let colStartClasses = [
  '',
  'col-start-2',
  'col-start-3',
  'col-start-4',
  'col-start-5',
  'col-start-6',
  'col-start-7',
]
