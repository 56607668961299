import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as AiIcons from 'react-icons/ai';

import { ActionTd, Table, TableContainer, TableWrapper, Td, Th, Tr } from '../TabelaReservas/styles';

import Button from '../../components/Button';
import Context, { IContext } from '../../context/Context';

import api from '../../services/api';
import CustomAlert from '../CustomAlert';
import { ITipEsp } from '../../types/TipEsp';
import { destratarData, tratarData } from '../../utils/tratarData';

export interface ICampos {
  campo: string;
  label: string;
  type?: 'text' | 'number' | 'date' | 'datetime-local' | 'email' | 'month' | 'range' | 'search' | 'tel' | 'time' | 'url' | 'week' | 'select' | 'checkbox' | 'radio';
  default?: any;
  options?: { value: string | number; label: string }[];
}

export interface IFormatoCampos {
  [key: string]: (value: any) => ReactNode;
}

interface ICadastroGenerico {
  entidade: string;
  endpoint: string;
  campos: ICampos[];
  formatoCampos?: IFormatoCampos;
  codReserva?: number;
}

interface IRegistro {
  [key: string]: any;
  editando?: boolean;
  cod?: number; // Adicionado para identificação de registros
}

function CadastroGenerico({ entidade, endpoint, campos, formatoCampos = {}, codReserva }: ICadastroGenerico) {
  const { setIsLoadingOverlay, usuario }: IContext = useContext(Context);
  const [data, setData] = useState<IRegistro[]>([]);
  const [tiposDeEspaco, setTiposDeEspaco] = useState<{ value: number; label: string }[]>([]);

  async function getData() {
    try {
      setIsLoadingOverlay(true);

      let params = {};

      if (endpoint === '/datasEspeciais') {
        params = { datini: tratarData(new Date().toISOString()) };
      }

      if (endpoint === '/convidados') {
        params = { codres: codReserva };
      }

      const response = await api.get(endpoint, { params });

      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error: any) {
      if (error.response?.data) return toast.error(error.response.data.erro);
      toast.error(`Erro ao buscar ${entidade}`);
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 500);
    }
  }

  async function getTiposDeEspaco() {
    try {
      const response = await api.get('/tiposDeEspaco');
      if (response.status === 200) {
        const options = response.data.map((tipo: ITipEsp) => ({ value: tipo.cod, label: tipo.tipesp }));
        setTiposDeEspaco(options);
      }
    } catch (error: any) {
      toast.error('Erro ao buscar Tipos de Espaço');
    }
  }

  async function postData(novoRegistro: IRegistro) {
    try {
      setIsLoadingOverlay(true);

      const response = await api.post(endpoint, novoRegistro);

      if (response.status === 201) {
        toast.success(`${entidade} criado com sucesso`);
        getData();
      }
    } catch (error: any) {
      if (error.response?.data) return toast.error(error.response.data.erro);
      toast.error(`Erro ao criar ${entidade}`);
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 500);
    }
  }

  async function putData(registro: IRegistro) {
    try {
      setIsLoadingOverlay(true);
      const response = await api.put(`${endpoint}/${registro.cod}`, registro);
      if (response.status === 200) {
        toast.success(`${entidade} atualizado com sucesso`);
        getData();
      }
    } catch (error: any) {
      if (error.response?.data) return toast.error(error.response.data.erro);
      toast.error(`Erro ao atualizar ${entidade}`);
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 500);
    }
  }

  async function deleteData(id: number) {
    try {
      setIsLoadingOverlay(true);
      const response = await api.delete(`${endpoint}/${id}`);
      if (response.status === 204) {
        toast.success(`${entidade} excluído com sucesso`);
        getData();
      }
    } catch (error: any) {
      if (error.response?.data) return toast.error(error.response.data.erro);
      toast.error(`Erro ao excluir ${entidade}`);
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 500);
    }
  }

  function handleButtonClick() {
    const novoRegistro = data.find(item => item.editando);

    if (!novoRegistro) {
      const novoItem = campos.reduce((acc, campo) => ({
        ...acc,
        [campo.campo]: campo.default || '',
        editando: true
      }), {});
      setData([...data, novoItem]);
      return;
    }

    if (codReserva) {
      novoRegistro['codres'] = codReserva;
      if (novoRegistro?.con.split(' ').length < 2) return toast.warning('Obrigatório informar Nome Completo do Convidado');
    }
    if (endpoint === '/espacos' || endpoint === '/datasEspeciais') {
      novoRegistro['codtipesp'] = novoRegistro?.tipesp?.cod;
    }

    delete novoRegistro['dathorins'];

    if (novoRegistro.cod) {
      putData(novoRegistro); // Atualiza o registro se um código existir
    } else {
      postData(novoRegistro); // Cria um novo registro se não houver código
    }
  }

  const renderCampo = (campo: ICampos, item: IRegistro, index: number) => {
    if (campo.type === 'select' && tiposDeEspaco.length > 0) {

      // if (item.editando) {
      //   return (
      //     <select
      //       defaultValue={item.tipesp.cod.toString()}
      //       onChange={(e) => {
      //         setData(data.map((data, i) => i === index ? { ...data, [campo.campo]: e.target.value } : data));
      //       }}
      //       disabled={!item.editando}
      //     >
      //       <option value='' disabled>Selecione</option>
      //       {tiposDeEspaco.map(option => (
      //         <option key={option.value} value={option.value}>{option.label}</option>
      //       ))}
      //     </select>
      //   );
      // }

      return tiposDeEspaco.find(option => option.value === item?.tipesp?.cod)?.label;
    }

    // if (campo.campo.includes('.')) {
    //   const keys = campo.campo.split('.');
    //   let value = item;
    //   keys.forEach(key => {
    //     value = value[key];
    //   });
    //   return formatoCampos[campo.campo] ? formatoCampos[campo.campo](value) : value;
    // }

    return formatoCampos[campo.campo] ? formatoCampos[campo.campo](item[campo.campo]) : item[campo.campo];
  };

  useEffect(() => {
    if (entidade === 'Espaço' || entidade === 'Datas Especiais') {
      getTiposDeEspaco();
    }

    getData();
  }, []);

  return (
    <TableContainer>
      <h1>{entidade === 'Convidado' ? 'Lista de Convidados' : `Cadastro de ${entidade}`}</h1>
      <br />
      <TableWrapper>
        <Table>
          <thead>
            <Tr>
              {campos.map(campo => <Th key={campo.campo}>{campo.label}</Th>)}
              <Th style={{ width: '100px' }}>Ações</Th>
            </Tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              !item.editando ? (
                <Tr key={index}>
                  {campos.map(campo => (
                    <Td key={campo.campo}>
                      {renderCampo(campo, item, index)}
                    </Td>
                  ))}
                  <ActionTd>
                    <AiIcons.AiFillEdit onClick={() => setData(data.map((data, i) => i === index ? { ...data, editando: true } : { ...data, editando: false }))} />
                    <AiIcons.AiFillDelete
                      onClick={() => CustomAlert({
                        title: `Exclusão de ${entidade}`,
                        message: `Deseja prosseguir com a exclusão?`,
                        onConfirm: async () => {
                          await deleteData(item.cod!);
                        },
                        onCancel: () => { }
                      })}
                    />
                  </ActionTd>
                </Tr>
              ) : (
                <Tr key={index} $editando>
                  {campos.map(campo => (
                    <Td key={campo.campo}>
                      {campo.type !== 'select' ? (
                        <input
                          type={campo.type || 'text'}
                          defaultValue={campo.type === 'date' ? destratarData(item[campo.campo]) : item[campo.campo] === 0 ? '0' : item[campo.campo] || ''}
                          onChange={(e) => {
                            setData(data.map((d, i) => i === index ? { ...d, [campo.campo]: e.target.value.replace(',', '.') } : d));
                          }}
                          style={{ width: '100%' }}
                        />
                      ) : (
                        <select
                          defaultValue={item.tipesp?.cod.toString() || ''}
                          onChange={(e) => {
                            setData(data.map((data, i) => i === index ? { ...data, tipesp: { cod: +e.target.value } } : data));
                          }}
                        >
                          <option value='' disabled>Selecione</option>
                          {tiposDeEspaco.map(option => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      )}
                    </Td>
                  ))}
                  <ActionTd>
                    <AiIcons.AiFillCheckCircle onClick={() => handleButtonClick()} />
                    <AiIcons.AiFillCloseCircle
                      onClick={() => {
                        if (item.cod) {
                          setData(data.map((data, i) => i === index ? { ...data, editando: false } : data));
                          return;
                        }
                        setData(data.filter((data, i) => i !== index))
                      }}
                    />
                  </ActionTd>
                </Tr>
              )
            ))}
          </tbody>
        </Table>
      </TableWrapper>
      <p style={{ textAlign: 'right' }}>Total de Registros: {data.length}</p>
      <Button
        texto={!data.some(item => item.editando) ? 'Novo' : 'Salvar'}
        onClick={handleButtonClick}
        style={{ width: '100%', margin: '10px 0px' }}
      />
    </TableContainer>
  );
}

export default CadastroGenerico;
